import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import LearnMore from "../templates/learnMore";
import entrance_3 from "../images/entrance_3.jpg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Virtual Offices"
      />
      <LearnMore
        title={`A virtual space to improve perception`}
        cta={`Get in touch`}
        image={entrance_3}
      >
        <div>
          <p className="text-xl">
            Whether you’re choosing to work from home for flexibility, family
            balance or to keep expenditure down, we know you’re making a
            strategic choice for your business. Let us support you with a
            dedicated virtual office membership that ensures your burgeoning
            reputation and market appeal is aligned with the positioning of your
            brand. Your Richmond (or as we like to call it “Silicon Cremorne”
            postcode ensure instant gratis and sets you up to flourish with our
            growing community of members from across the country.
          </p>
        </div>
        <div>
          <p className="text-xl pt-8 md:pt-0">
            Seasoned travellers, such as consultants and salespeople, know the
            importance of a secure and centralised mailing address. Utilising
            your daily mail notifications, you will know the minute something
            has arrived, no matter where in the world you might be. With the
            Mailing Address Essentials membership, you’re welcome to relax with
            your premium Nespresso coffee in our stylish surroundings while you
            recalibrate and get up to date with your parcels and mail.{" "}
          </p>
        </div>
      </LearnMore>
    </Layout>
  );
}

export default IndexPage;
